import { useState, useContext } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { FilledButton } from '../../components/Button';
import { Dropdown } from '../../components/Dropdown';
import { Input } from '../../components/Input';
import { colors } from '../../components/Theme/Colors';
import { media } from '../../components/Theme/Media';
import { Heading3, Heading5 } from '../../components/Theme/Typography';
import { submitApplyForm } from '../../services';
import { AppContext } from '../../context/AppContext';

const Wrapper = styled.section`
  width: 100%;
  padding: 10rem 0 30rem;
  background-color: ${colors.backgroundMedium};

  h3 {
    ${Heading3};
    max-width: 75.4rem;
    margin: auto;

    font-weight: 400;
    line-height: 7.104rem;
    letter-spacing: 2%;
    margin-bottom: 6rem;
  }

  form {
    max-width: 75.4rem;
    margin: auto;

    .input-wrapper:not(:last-child) {
      margin-bottom: 6rem;
    }
  }

  input[type='file']::file-selector-button {
    display: none;
  }

  ${media.mobileWide`
    padding: 6rem 1.6rem 30rem;

    h3 {
      ${Heading5};
      line-height: 4.736rem;
      letter-spacing: 0.02em;
      margin-bottom: 3.9rem;
    }

    form {
      .input-wrapper:not(:last-child){
        margin-bottom: 3.2rem;
      }
    }
  `}
`;

/* eslint-disable max-len */
const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

const isRequired = value =>
  value && value.length > 0 ? undefined : 'Required';

const isEmail = value =>
  /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
    ? undefined
    : 'Invalid Email Address';

const isPhone = value =>
  /^[0-9]*$/.test(value) ? undefined : 'Invalid Phone Number';

/* eslint-disable */
export const ApplyForm = ({ jobId }) => {
  const [resumeFileName, setResumeFileName] = useState('');
  const [coverLetterFileName, setCoverLetterFileName] = useState('');
  const [submit, setSubmit] = useState(false);
  const { isOpenConfirmModal, setOpenConfirmModal } = useContext(AppContext);
  const onSubmit = async (values, { restart }) => {
    const res = await submitApplyForm(values);
    if(res) {
      setOpenConfirmModal(!isOpenConfirmModal);
    }
    setTimeout(restart);
    setResumeFileName('');
    setCoverLetterFileName('');
    setSubmit(false);
  };

  return (
    <Wrapper id="apply-now">
      <h3>Apply for this job</h3>
      <Form
        initialValues={{ jobId }}
        render={({ handleSubmit, submitting, form }) => (
          <form
            onSubmit={event => {
              setSubmit(true);
              handleSubmit(event);
            }}
          >
            <Field name="name" validate={isRequired}>
              {({ input: { ...inputRest }, meta }) => (
                <Input
                  {...inputRest}
                  autoComplete="name"
                  label="FULL NAME *"
                  meta={meta}
                  name="name"
                  type="text"
                />
              )}
            </Field>
            <Field
              name="email"
              validate={composeValidators(isRequired, isEmail)}
            >
              {({ input: { ...inputRest }, meta }) => (
                <Input
                  {...inputRest}
                  autoComplete="email"
                  label="EMAIL *"
                  meta={meta}
                  name="email"
                  type="text"
                />
              )}
            </Field>
            <Field
              name="phone"
              validate={composeValidators(isRequired, isPhone)}
            >
              {({ input: { ...inputRest }, meta }) => (
                <Input
                  {...inputRest}
                  autoComplete="phone"
                  label="PHONE NUMBER *"
                  meta={meta}
                  name="phone"
                  type="text"
                />
              )}
            </Field>
            <Field name="resume" validate={isRequired}>
              {({ input: { value, onChange, ...inputRest }, meta }) => {
                const handleChange = ({ target }) => {
                  const file = target.files[0];

                  onChange(target.files);
                  setResumeFileName(file.name);
                };

                return (
                  <Input
                    {...inputRest}
                    accept=".pdf,.txt,.rtf,.doc,.docx"
                    fileName={resumeFileName}
                    label="RESUME/CV *"
                    meta={meta}
                    submit={submit}
                    type="file"
                    onChange={handleChange}
                  />
                );
              }}
            </Field>
            <Field name="source_id" validate={isRequired}>
              {({ input: { ...inputRest }, meta }) => {               
                return (
                  <Dropdown 
                  {...inputRest}
                  meta={meta}
                  />
                );
              }}
            </Field>
            
            <FilledButton disabled={submitting} type="submit">
              Apply for this position
            </FilledButton>
          </form>
        )}
        onSubmit={onSubmit}
      />
    </Wrapper>
  );
};

ApplyForm.propTypes = {
  jobId: PropTypes.any,
};
