import styled from '@emotion/styled';
import { media } from '../Theme/Media';
import { Heading6 } from '../Theme/Typography';

export const H6 = styled.h6`
  ${Heading6};

  ${media.mobileWide`
    font-size: 2rem;
  `}
`;
