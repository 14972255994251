import styled from '@emotion/styled';
import dompurify from 'dompurify';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { ArrowLeft } from '../../components/Shape/ArrowLeft';
import { SharingDropdown } from '../../components/SharingDropdown';
import { H6 } from '../../components/Text/H6';
import { P2 } from '../../components/Text/P2';
import { colors } from '../../components/Theme/Colors';
import { media } from '../../components/Theme/Media';
import { Body1, Heading6 } from '../../components/Theme/Typography';
import { PAGE_ROUTES } from '../../utils/constants';

const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colors.line};
  margin-bottom: 2.2rem;
`;

const JobDescriptionWrapper = styled.section`
  max-width: 114.5rem;
  margin: 0 auto;
  padding-bottom: 10rem;
  color: ${colors.textColor};

  .job-desc__sharing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10rem;

    a {
      text-decoration: none;
      color: ${colors.textColor};
      position: relative;
      .arrow-left-icon {
        transition: all 0.5s ease;
        position: absolute;
        top: 50%;
        opacity: 0;
        transform: translate(-2.9rem, -50%);
      }
      p {
        display: inline-block;
      }
      &:hover {
        .arrow-left-icon {
          opacity: 1;
          transform: translate(-4rem, -50%);
        }
      }
      &:active,
      &:focus {
        color: ${colors.eerie800};
        .arrow-left-icon {
          opacity: 0;
        }
      }
    }
  }

  .job-desc__content {
    max-width: 75.4rem;
    margin: 0 auto;

    h6 {
      ${Heading6};
      margin-bottom: 4rem;
      line-height: 126%;
      font-weight: 400;
      letter-spacing: 2%;
    }

    h6:not(:first-of-type) {
      margin-top: 6rem;
    }

    ul {
      ${Body1};
      line-height: 2.4rem;
      letter-spacing: 4%;
      list-style: none;
      margin-left: 0;

      li:not(:last-child) {
        margin-bottom: 1.6rem;
      }

      li {
        display: flex;
      }

      li {
        span {
          display: flex;
          &:before {
            content: '.';
            margin-right: 2.4rem;
            display: block;
          }
        }
      }
    }
  }

  ${media.mobileWide`
    padding: 0 1.6rem;
    .job-desc__sharing {
      margin-bottom: 8rem;
      a .arrow-left-icon {
        display: none;
      }
    }

    .job-desc__content {
      h6 {
        margin-bottom: 2.4rem;
      }
    }
  `}
`;

export const JobDescription = ({ job }) => {
  const {
    requirements = '',
    job_overview: jobOverview,
    job_perks: jobPerks,
  } = job;

  const renderInfoItems = (item, key) => {
    if (item) {
      return item.includes('<b>', 0) ? (
        <li
          key={key}
          dangerouslySetInnerHTML={{
            __html: dompurify.sanitize(item),
          }}
        />
      ) : (
        <li key={key}>
          <span
            dangerouslySetInnerHTML={{
              __html: dompurify.sanitize(item),
            }}
          />
        </li>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <Line />
      <JobDescriptionWrapper>
        <div className="job-desc__sharing">
          <Link className="hoverable" to={PAGE_ROUTES.Jobs}>
            <ArrowLeft className="arrow-left-icon" color={colors.textColor} />
            <P2>Back to Career</P2>
          </Link>
          <SharingDropdown />
        </div>
        <div className="job-desc__content">
          <H6>Responsibilities</H6>
          <ul>
            {jobOverview &&
              jobOverview.split('\n').map((i, idx) => renderInfoItems(i, idx))}
          </ul>
          <H6>Qualifications</H6>
          <ul>
            {requirements &&
              requirements.split('\n').map((i, idx) => renderInfoItems(i, idx))}
          </ul>
          <H6>Our Perks</H6>
          <ul>
            {jobPerks ? (
              jobPerks.split('\n').map((i, idx) => renderInfoItems(i, idx))
            ) : (
              <>
                <li>
                  <span>
                    Working time: 8:00 - 17:00, Monday - Friday (Flexi time from
                    7:00 am - 9:00 am)
                  </span>
                </li>
                <li>
                  <span>Macbook Pro provided</span>
                </li>
                <li>
                  <span>
                    13th-month salary + KPI bonus at the end of the year
                  </span>
                </li>
                <li>
                  <span>Performance review twice a year</span>
                </li>
                <li>
                  <span>Open working environment</span>
                </li>
                <li>
                  <span>Regular training & sharing sessions</span>
                </li>
                <li>
                  <span>Onsite opportunity in Korea</span>
                </li>
                <li>
                  <span>
                    Events & activities: company trip at least once a year, monthly company parties & other Teambuilding activities
                  </span>
                </li>
                <li>
                  <span>
                    Other benefits such as holidays and insurance benefits will follow Vietnam laws
                  </span>
                </li>
                <li>
                  <span>Free coffee, snack and drinks</span>
                </li>
              </>
            )}
          </ul>
        </div>
      </JobDescriptionWrapper>
    </>
  );
};

JobDescription.propTypes = {
  job: PropTypes.any,
};
