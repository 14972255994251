import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { FilledButton } from '../../components/Button';
import { H2 } from '../../components/Text/H2';
import { H6 } from '../../components/Text/H6';
import { media } from '../../components/Theme/Media';
import { isDesktopScreen } from '../../utils/constants';

const PromotionWrapper = styled.section`
  padding: 21.2rem 0 0;
  margin: 0 auto;
  margin-bottom: 10rem;
  max-width: 114.5rem;

  ${media.desktopWide`
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  `};

  h2 {
    margin-bottom: 5rem;
  }

  .promotion__content {
    margin: 0 auto;

    .promotion__desciption {
      display: flex;
      justify-content: space-between;

      h6 {
        max-width: 76.8rem;
        margin-bottom: 1rem;
      }

      button {
        min-width: 18rem;
      }

      .promotion__apply_btn {
        max-height: 7.2rem;
      }
    }
  }

  ${media.mobileWide`
    padding: 25rem 1.6rem 5rem;
    margin-bottom: 4rem;


    .promotion__content {
      h2 {
        word-break: break-word;
      }

      .promotion__desciption{
        flex-direction: column;
        align-items: flex-start;

        h6 {
          margin-bottom: 2.4rem;
        }
      }
    }
  `}
`;

export const Promotion = ({ job }) => {
  const handleScrollToApplyForm = event => {
    if (isDesktopScreen) {
      event.preventDefault();
      const slider = document.querySelector('#apply-now');

      window.scroll.scrollTo(slider);
    }
  };

  return (
    <PromotionWrapper>
      <div className="promotion__content">
        <H2>{job.name}</H2>
        <div className="promotion__desciption">
          <div>
            {job.description &&
              typeof job.description === 'string' &&
              job.description
                .split('\n')
                .map((i, idx) => <H6 key={idx}>{i}</H6>)}
          </div>
          <a
            className="promotion__apply_btn"
            href="#apply-now"
            onClick={handleScrollToApplyForm}
          >
            <FilledButton>Apply now</FilledButton>
          </a>
        </div>
      </div>
    </PromotionWrapper>
  );
};

Promotion.propTypes = {
  job: PropTypes.any,
};
