import styled from '@emotion/styled';
import { colors } from '../Theme/Colors';
import { media } from '../Theme/Media';
import { Body4, Body1 } from '../Theme/Typography';
import { isDarkMode } from '../../utils/app';

const StyledInput = styled.div`
  position: relative;

  label {
    ${Body4};
    font-style: normal;
    font-weight: 500;
    line-height: 1.7rem;
    letter-spacing: 19%;
    margin-bottom: 1.6rem;
    display: inline-block;
  }

  .input-container {
    background-color: ${colors.sharingListBg};
    border-radius: 4px;
    position: relative;

    span {
      position: absolute;
      right: 2.4rem;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

      font-size: 1.8rem;
      line-height: 1.8rem;
      letter-spacing: 4%;
      border-bottom: 1px solid ${colors.eerie500};
      color: ${colors.textColor};
    }

    &:hover {
      span {
        color: ${colors.productLink};
      }
    }

    &:active,
    &:focus {
      span {
        color: ${colors.textColor};
      }
    }

    .file-name {
      left: 2.4rem;
      border-bottom: none;
    }
  }

  .input__err-msg {
    ${Body1};
    font-weight: 400;
    line-height: 2.4rem;

    color: ${colors.ruby};
    position: relative;
    top: 0.6rem;
    left: 0;
    display: inline-block;
  }

  .input-ext {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.068rem;
    margin-top: 2.4rem;
  }

  input {
    ${Body1}
    color: ${({ type }) =>
      type === 'file' ? 'transparent' : colors.textColor};
    cursor: ${({ type }) => (type === 'file' ? 'pointer' : 'default')};
    font-weight: 400;
    position: relative;
    line-height: 2.4rem;
    border: 2px solid
      ${({ hasError }) =>
        hasError
          ? `${colors.ruby} !important`
          : `${isDarkMode ? colors.outlineButtonBor : 'transparent'}`};
    outline: 0;
    border-radius: 4px;
    caret-color: ${({ hasError }) => (hasError ? colors.ruby : colors.organge)};
    z-index: 2;

    padding: 2.4rem;
    width: 100%;
    background-color: transparent;

    &:focus {
      border: 2px solid ${colors.organge};
    }

    ::-webkit-file-upload-button {
      display: none;
    }
  }

  ${media.mobileWide`
    .input-ext{
      display: none;
    }

    input {
      padding: 1.5rem;
    }

    input[type="file"] {
      padding: 2.6rem;
      font-size: 0;
      line-height: 0;
    }
  `}
`;

export const Input = ({
  label,
  name,
  errMsg,
  meta = {},
  type,
  fileName,
  submit,
  ...props
}) => {
  const { error, touched } = meta;

  const getError = () => {
    switch (type) {
      case 'file':
        return touched && submit && (errMsg || error);
      default:
        return touched && (errMsg || error);
    }
  };

  const hasError = getError();

  return (
    <StyledInput className="input-wrapper" hasError={hasError} type={type}>
      <label htmlFor={name}>{label}</label>
      <div className="input-container">
        <input id={name} name={name} type={type} {...props} />
        {type === 'file' && <span>Attach</span>}
        {fileName && <span className="file-name">{fileName}</span>}
      </div>
      {hasError && <span className="input__err-msg">{error || errMsg}</span>}
      {type === 'file' && (
        <p className="input-ext">Allow formats are: pdf, doc, docx, txt, rtf</p>
      )}
    </StyledInput>
  );
};
