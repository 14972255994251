import { useState } from 'react';
import styled from '@emotion/styled';
import arrow_down from '../../../static/images/arrow_down_location.svg';
import arrow_down_white from '../../../static/images/arrow_down_location_white.svg';
import { isDarkMode } from '../../utils/app';
import { colors } from '../Theme/Colors';
import { media } from '../Theme/Media';

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 6rem;

  div {
    position: relative;
    width: 100%;
    height: 7.2rem;
    border-radius: 0.4rem;
    padding-left: 2.4rem;

    text-align: left;
    line-height: 7.2rem;
    color: ${({ hasValue }) =>
      hasValue ? colors.textColor : colors.selectPlaceholder};
    font-size: 1.8rem;

    background-color: ${colors.sharingListBg};
    border: 2px solid ${isDarkMode ? colors.outlineButtonBor : 'transparent'};
    border-color: ${({ isDisplayed }) =>
      isDisplayed
        ? colors.organge
        : isDarkMode
        ? colors.outlineButtonBor
        : 'transparent'};
    ${({ hasError }) => (hasError ? 'border-color:' + colors.ruby : '')};

    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 2.4rem;
      transform: translateY(-50%)
        ${({ isDisplayed }) =>
          isDisplayed ? 'rotateZ(180deg)' : 'rotateZ(0deg)'};
      transition: transform 0.5s;

      width: 2.3rem;
      height: 1.3rem;
      background-image: url('${isDarkMode ? arrow_down_white : arrow_down}');
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  ul {
    list-style-type: none;
    background-color: ${colors.dropdownBackground};
    border-radius: 0.4rem;
    position: absolute;
    left: -0.2rem;
    top: calc(7.2rem + 0.8rem);

    width: calc(100% + 0.4rem);
    padding: 0.4rem;
    color: ${colors.textColor};
    box-shadow: 0 0.2rem 2rem 0.2rem rgba(138, 138, 138, 0.24);

    li {
      min-height: 4.4rem;
      line-height: 2rem;
      padding: 0 2rem;

      font-weight: 500;
      font-size: 1.6rem;
      letter-spacing: 0.04em;
      border-radius: 0.4rem;

      display: flex;
      align-items: center;

      &.active {
        background-color: ${colors.sharingListItemBgFocused};
      }

      &::selection {
        background-color: transparent;
      }
      &:hover {
        background-color: ${colors.sharingListItemBgHover};
      }
      &:active {
        background-color: ${colors.sharingListItemBgFocused};
      }
    }
  }

  .dropdown__err-msg {
    font-size: 1.8rem;
    letter-spacing: 0.04em;
    font-weight: 400;
    line-height: 2.4rem;
    color: ${colors.ruby};

    margin-top: 0.6rem;
  }

  .dropdown__label {
    font-size: 1.2rem;
    letter-spacing: 0.19em;
    font-style: normal;
    font-weight: 500;
    line-height: 1.7rem;
    margin-bottom: 1.6rem;
    text-transform: uppercase;
  }

  ${media.mobileWide`
    div {
      height: 5.4rem;
      line-height: 5.4rem;
      padding: 0 1.5rem;
      -webkit-tap-highlight-color: transparent;

      span {
        white-space: nowrap;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }

      ul {
        top: calc(5.4rem + 0.8rem);
      }
    }
  `}
`;

const items = {
  4: 'Facebook',
  17: 'LinkedIn',
  18: 'ITviec',
  19: 'Other job sites (SmartRecruiters, Indeed etc)',
  20: `LECLE's website`,
  21: 'Internet Search',
  22: 'Recommendation',
  23: 'Other',
};

export const Dropdown = ({ value, meta, onChange = () => {} }) => {
  const [isDisplayed, setIsDisplayed] = useState(false);

  const toggle = () => {
    setIsDisplayed(!isDisplayed);
  };

  const onItemClick = id => {
    onChange({
      target: {
        value: id,
      },
    });
  };

  const { error, touched } = meta;
  const hasError = touched && error;

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  return (
    <Wrapper hasError={hasError} hasValue={value} isDisplayed={isDisplayed}>
      <p className="dropdown__label">
        How did you hear about our job position?
      </p>
      <div onClick={toggle}>
        <span>{items[value] || 'Select an option'}</span>
        {isDisplayed && (
          <ul className="dropdown-items">
            {Object.keys(items).map(id => (
              <li
                key={id}
                className={id === value ? 'active' : ''}
                onClick={() => onItemClick(id)}
              >
                {items[id]}
              </li>
            ))}
          </ul>
        )}
      </div>
      {hasError && <p className="dropdown__err-msg">{error}</p>}
    </Wrapper>
  );
};
