import { useState } from 'react';
// import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { getWindow } from '../../utils/app';
import { colors } from '../Theme/Colors';
import { media } from '../Theme/Media';

const Wrapper = styled.div`
  position: relative;

  button {
    outline: 0;
    cursor: pointer;
    border: 2px solid ${colors.outlineButtonBor};
    border-radius: 4px;
    padding: 1.8rem 4.8rem;
    font-size: 1.6rem;
    font-weight: 500;

    line-height: 2.016rem;
    letter-spacing: 4%;

    color: ${colors.textColor};
    background-color: ${colors.outlineButtonBg};

    &:hover {
      background-color: ${colors.outlineButtonBgHover};
      border-color: ${colors.outlineButtonBorHover};
    }
    &:active,
    &:focus {
      background-color: ${colors.outlineButtonBgFocused};
      border-color: ${colors.outlineButtonBor};
    }
  }

  ul {
    list-style-type: none;
    width: 100%;
    padding: 0.4rem;
    margin-top: 0.4rem;
    box-shadow: ${colors.sharingListBoxShadow};
    position: absolute;
    border-radius: 0.4rem;
    background-color: ${colors.sharingListBg};
    display: none;
    &.is-show {
      display: block;
    }

    li {
      padding: 1.2rem 0;
      cursor: pointer;
      width: 100%;
      text-align: center;

      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.016rem;
      letter-spacing: 4%;

      &:hover {
        background-color: ${colors.sharingListItemBgHover};
        border-radius: 0.4rem;
      }

      &:active,
      &:focus {
        background-color: ${colors.sharingListItemBgFocused};
      }
    }
  }

  ${media.mobileWide`
    button {
      padding: 1rem 3.2rem;
    }
  `}
`;

export const SharingDropdown = () => {
  const [display, setDisplay] = useState(false);

  const toggleMenu = () => {
    setDisplay(!display);
  };

  const url = getWindow().location.href;

  return (
    <Wrapper>
      <button className="hoverable" type="button" onClick={toggleMenu}>
        Share
      </button>
      <ul className={`${display ? 'is-show' : ''}`}>
          <li className="hoverable">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                url,
              )}`}
              rel="noreferrer"
              target="_blank"
              onClick={toggleMenu}
            >
              Facebook
            </a>
          </li>
          <li className="hoverable">
            <a
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                url,
              )}`}
              rel="noreferrer"
              target="_blank"
              onClick={toggleMenu}
            >
              Linkedin
            </a>
          </li>
        </ul>
    </Wrapper>
  );
};

SharingDropdown.propTypes = {};

export default SharingDropdown;
