import styled from '@emotion/styled';
import { media } from '../Theme/Media';
import { Heading2, Heading4 } from '../Theme/Typography';

export const H2 = styled.h2`
  ${Heading2};

  ${media.mobileWide`
    ${Heading4};
  `}
`;
