import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { colors } from '../Theme/Colors';
import { media } from '../Theme/Media';
import { resetButton } from '../Theme/Mixins';
import { Body2 } from '../Theme/Typography';

export const Button = styled.button`
  ${Body2};
  ${resetButton};
  color: ${colors.cultured};
  background-color: ${colors.buttonFill};
  max-height: 7.2rem;

  padding: 2.6rem 4.8rem;
  border-radius: 0.4rem;

  &:hover {
    /* box-shadow: 0px 2px 48px 4px rgba(67, 67, 67, 0.3); */
    background-color: ${colors.buttonHover};
  }

  &:active,
  &:focus {
    background-color: ${colors.buttonFocused};
  }

  ${media.mobileWide`
    padding: 1.4rem 3.2rem;
  `}
`;
export const FilledButton = ({ children, className, ...props }) => (
  <Button className={`hoverable ${className}`} {...props}>{children}</Button>
);

FilledButton.propTypes = {
  children: PropTypes.any.isRequired,
};
