import styled from '@emotion/styled';
import { colors } from '../Theme/Colors';
import PropTypes from 'prop-types';
import { media } from '../Theme/Media';

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba(20, 20, 20, 0.6);
  &.open {
    display: block;
  }
  .modal-dialog {
    position: relative;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    max-width: 36.2rem;
    background: #ffffff;
    border-radius: 0.4rem;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 2.4rem;
    color: #141414;
    padding: 2.4rem;
  }
  ${media.mobileWide`
    .modal-dialog {
        width: 100%;
    }
  `}
`;

export const Modal = ({ children, isOpen }) => {
  return (
    <StyledModal className={isOpen ? 'open' : ''}>
      <div className="modal-dialog">
        <div className="modal-content">{children}</div>
      </div>
    </StyledModal>
  );
};

Modal.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
};
