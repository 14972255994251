import { useEffect, useState, useContext } from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { Layout } from '../components/Layout';
import { Promotion, JobDescription, ApplyForm } from '../containers/JobDetails';
import { getJobList, detailedJobUrl } from '../services';
import { handleHoverCursor } from '../utils/app';
import { Modal } from '../components/Modal';
import { FilledButton } from '../components/Button';
import { AppContext } from '../context/AppContext';
import { PAGE_ROUTES } from '../utils/constants';

const GoToHomeButton = styled(FilledButton)`
  width: 100%;
  margin-top: 3.2rem;
`;

const JobDetails = ({ location }) => {
  const [job, setJob] = useState({});
  const { isOpenConfirmModal, setOpenConfirmModal } = useContext(AppContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jobId = +searchParams.get('id');

    if (jobId) {
      getJobList(detailedJobUrl)
        .then(data => data.filter(i => i.department_id && i.id === jobId))
        .then(([job]) => {
          setJob(job);
        })
        .catch(() => {
          navigate('/404');
        });
    } else {
      navigate('/404');
    }
  }, []);

  useEffect(() => {
    job && handleHoverCursor(true);

    return () => {
      job && handleHoverCursor(false);
    };
  }, [job]);

  const onRedirectToHomePage = () => {
    setOpenConfirmModal(!isOpenConfirmModal);
    navigate(PAGE_ROUTES.HomePage);
  };

  return (
    <Layout
      hasFooter={false}
      location={location}
      seo={{ title: job?.name }}
      modalContainer={
        <Modal isOpen={isOpenConfirmModal}>
          Your request was successfully submitted. Thanks for applying!
          <GoToHomeButton className="go-to-hone" onClick={onRedirectToHomePage}>
            Go to Home
          </GoToHomeButton>
        </Modal>
      }
    >
      {job?.id && (
        <>
          <Promotion job={job} />
          <JobDescription job={job} />
          <ApplyForm jobId={job?.id} />
        </>
      )}
    </Layout>
  );
};

export default JobDetails;
