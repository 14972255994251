import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const IconWrapper = styled.figure`
  display: inline-block;

  svg {
    path {
      transition: fill 0.3s;
    }
  }
`;

export const ArrowLeft = ({ color = '#141414', className }) => (
  <IconWrapper className={className} color={color}>
    <svg
      width="24"
      height="12"
      viewBox="0 0 24 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9874 5.025H2.7374L4.7249 2.625C5.1374 2.2125 5.0249 1.5375 4.6124 1.2375C4.1999 0.824999 3.5249 0.9375 3.2249 1.35L0.412402 4.65C-0.187598 5.4375 -0.187598 6.5625 0.412402 7.35L3.2249 10.65C3.3374 10.95 3.6374 11.0625 3.9374 11.0625C4.1249 11.0625 4.4249 10.95 4.5374 10.875C4.9499 10.4625 5.0249 9.8625 4.6499 9.4875L2.6624 7.0875H22.9874C23.5874 7.0875 23.9999 6.675 23.9999 6.075C23.9999 5.4375 23.5874 5.025 22.9874 5.025Z"
        fill={color}
      />
    </svg>
  </IconWrapper>
);

ArrowLeft.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};
